/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React from "react";

export function AdvanceTablesWidget4({ className }) {
  return (
    <div className={`card card-custom ${className}`}>
      {/* Head */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            Example Subscription
          </span>
        </h3>
        <div className="card-toolbar">
          <a
            href="https://ginses.com/documentation/"
            className="btn btn-info font-weight-bolder font-size-sm mr-3"
          >
            Full Documentation
          </a>
        </div>
      </div>
      {/* Body */}
      <div className="card-body pt-0 pb-3">
        <pre>{`
subscription getMarket($asset: [String!]) {
  market(where: { s: { _in: $asset } }) {
    s   // symbol
    b   // bid
    bd  // bid direction
    a   // ask
    ad  // ask direction
    p   // precession
    ts  // timestamp
    vd1 // volume d1
  }
}
        `}</pre>
      </div>
    </div>
  );
}
