/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import { Nav, Tab } from "react-bootstrap";
import MarketData from "../../../layout/components/MarketData";

export function AdvanceTablesWidget2({ className }) {
  const [key, setKey] = useState("crypto");

  return (
    <div className={`card card-custom ${className}`}>
      {/* Head */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            GraphQL Demonstration
          </span>
          <span className="text-muted mt-3 font-weight-bold font-size-sm">
            Example use-case | Real-time data
          </span>
        </h3>
        <div className="card-toolbar">
          <Tab.Container defaultActiveKey={key}>
            <Nav
              as="ul"
              onSelect={_key => setKey(_key)}
              className="nav nav-pills nav-pills-sm nav-dark-75"
            >
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                  eventKey="fx"
                  className={`nav-link py-2 px-4 ${
                    key === "fx" ? "active" : ""
                  }`}
                >
                  Forex
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                  eventKey="crypto"
                  className={`nav-link py-2 px-4 ${
                    key === "crypto" ? "active" : ""
                  }`}
                >
                  Crypto
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>
        </div>
      </div>
      {/* Body */}
      <div className="card-body pt-3 pb-0">
        <div className="table-responsive">
          <MarketData tab={key} />
        </div>
      </div>
    </div>
  );
}
