import React, { useEffect, useState } from "react";
import { useSubscription, gql } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import moment from 'moment';

const MARKET_SUBSCRIPTION = gql`
  subscription getMarket($asset: [String!]) {
    distinct_market(where: { s: { _in: $asset }}, order_by: {s: desc}) {
      s
      b
      bd
      a
      ad
      ts
    }
  }
`;

const MARKET_QUERY = gql`
  subscription getMarket($asset: [String!]) {
    market(where: { s: { _in: $asset }}) {
      s
      b
      bd
      a
      ad
      p
      ts
    }
  }
`;

const syms = {
  fx: ['EURUSD', 'USDJPY', 'GBPUSD', 'AUDUSD', 'USDCHF', 'NZDUSD', 'USDCAD', 'EURCHF', 'XAUUSD'],
  futures: ['ENQ', 'ES', 'M2K', 'MNQ', 'MYM', 'CLE', 'DB', 'DD', 'DSX', 'EU6', 'GCE', 'AP', 'GLE', 'HSI', 'YM'],
  indices: ['AUS200', 'EU50', 'FRA40', 'DE30', 'HK50', 'JPN225', 'ESP35', 'UK100', 'US30', 'US500', 'UT100', 'UKOIL', 'USOIL'],
  crypto: ['BCHUSD', 'BCHJPY', 'BNBUSD', 'BTCUSD', 'BTCEUR', 'ETHUSD', 'LTCUSD'],
};

const MarketData = ({ tab }) => {
  const [state, setState] = useState({
    quotes: [],
    asset: [],
    query: MARKET_QUERY,
  });
  const { asset, query, quotes } = state;
  const { loading, error, data } = useSubscription(query, {
    variables: { asset },
  }, [tab]);

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      quotes: [],
      asset: syms[tab],
      query: MARKET_QUERY,
    }))
  }, [tab]);

  useEffect(() => {
    if (data && data.market) {
        if (quotes && quotes.length === 0) {
          setState(prevState => ({
            ...prevState,
            quotes: data.market,
            query: MARKET_SUBSCRIPTION,    
          }));
        }
    }
  }, [data, quotes]);

  useEffect(() => {
    if (data && data.distinct_market) {
      setState(prevState => ({
        // merge without duplicates
        ...prevState,
        quotes: prevState.quotes.map(prevQuote => ({
          ...prevQuote,
          ...data.distinct_market.find(quote => quote.s === prevQuote.s),
        })),
      }));
    }
  }, [data]);

  if (loading || (quotes && quotes.length === 0)) {
    return <center><CircularProgress style={{ margin: 20 }} className="splash-screen-spinner" /></center>;
  }
  if (error) {
    console.error(error);
    return <span>Error!</span>;
  }

  return (
    <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
      <thead>
        <tr className="text-left text-uppercase">
          <th className="pl-7 text-dark-75" style={{ width: 200 }}>Asset</th>
          <th className="text-center" style={{ width: 150, maxWidth: 150 }}>Bid</th>
          <th className="text-center" style={{ width: 150, maxWidth: 150 }}>Ask</th>
          <th className="text-center" style={{ width: 200, maxWidth: 200 }}>Time (UTC)</th>
          {/* <th className="text-center" style={{ width: 150, maxWidth: 150 }}>Volume (D1)</th> */}
        </tr>
      </thead>
      <tbody>
        {state.quotes && state.quotes.length > 0 &&
          state.quotes.map((asset) => {
            const bidColor = (direction) => {
              switch (direction) {
                case 1:
                  return '#3452ff';
                case -1:
                  return '#ff1053';
                default:
                  return;
              }
            }
            return (
              <tr key={asset.s}>
                <td className="text-left pl-7">
                  <span style={{ fontSize: 16 }} className="text-dark-75 font-weight-bold d-block">
                    {asset.s}
                  </span>
                  {/* <span className="text-muted font-size-xs">{asset.n}</span> */}
                </td>
                <td className="text-center">
                  <span style={{ color: bidColor(asset.bd), fontSize: 16 }} className="font-weight-100 d-block">
                    {asset.b.toFixed(asset.p)}
                  </span>
                </td>
                <td className="text-center">
                  <span style={{ color: bidColor(asset.ad), fontSize: 16 }} className="font-weight-100 d-block">
                    {asset.a.toFixed(asset.p)}
                  </span>
                </td>
                <td className="text-center">
                  <span className="text-muted font-weight-500">
                    {asset.ts && moment(asset.ts).format('DD.MM.YYYY | HH:mm:ss:SSS')}
                  </span>
                </td>
                {/* <td className="text-center">
                  <span className="label label-lg label-light-primary label-inline">
                    {asset.vd1}
                  </span>
                </td> */}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default MarketData;
