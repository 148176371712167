import React from "react";
import { AdvanceTablesWidget2, AdvanceTablesWidget4 } from "../widgets";
import {
  ApolloClient,
  ApolloProvider,
  split,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";

const httpLink = new HttpLink({
  uri:
    "https://graphql.leverage.exchange/v3",
});

const wsLink = new WebSocketLink({
  uri: `wss://graphql.leverage.exchange/v3`,
  options: {
    reconnect: true,
    connectionParams: {
      headers: {
        "ginses-token": "w6GWoH6CE9sozq5nZOpXamD9tx4Mnb2I"
      }
    }
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const createApolloClient = () => {
  return new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
  });
};

const client = createApolloClient();

export function Demo1Dashboard() {
  return (
    <>
      <div className="row">
        <div className="col-xxl-12 order-1 order-xxl-1">
          <ApolloProvider client={client}>
            <AdvanceTablesWidget2 className="card-stretch gutter-b" />
          </ApolloProvider>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <AdvanceTablesWidget4 className="card-stretch gutter-b" />
        </div>
      </div>
    </>
  );
}
